import { SerializedError } from '@reduxjs/toolkit';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { matchPath } from 'react-router-dom';
import { z } from 'zod';

import {
  PaginatedParams,
  PaginatedResponse,
  WithPaginationResponse,
} from './types.shared';

import { logOut, updateTokens } from 'store/slices/auth';
import { RootState } from 'store/store';

const mutex = new Mutex();
const API_PATH = process.env.REACT_APP_API_PATH ?? '';

const EXCLUDED_ENDPOINTS = ['login'];

const apiErrorSchema = z.object({
  error: z.string(),
  message: z.string(),
  status: z.number(),
});
export type APIError = z.infer<typeof apiErrorSchema>;

export const isAPIError = (error: unknown): error is APIError => {
  return apiErrorSchema.safeParse(error).success;
};

const extraOptionsSchema = z.object({
  requireAuth: z.boolean().catch(true),
  version: z.enum(['v1', 'v2']).optional(),
});

export interface CustomFetchArgs extends FetchArgs {
  extraOptions?: BaseQueryCustomOptions;
}

type BaseQueryCustomOptions = Partial<z.infer<typeof extraOptionsSchema>>;

type QueryParameters = Parameters<ReturnType<typeof fetchBaseQuery>>;

const baseQuery = (
  arg: QueryParameters[0],
  api: QueryParameters[1],
  extraOptions?: BaseQueryCustomOptions,
) => {
  const argExtraOptions =
    typeof arg === 'string' ? undefined : (arg as any)?.extraOptions;
  const { version } = extraOptionsSchema.parse(
    argExtraOptions ?? extraOptions ?? {},
  );

  const baseUrl =
    version === 'v2' ? API_PATH.replace('/api/v1/', '/api/v2/') : API_PATH;

  const commonFetchBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers, api) {
      const token = (api.getState() as RootState).auth.accessToken;
      const { requireAuth } = extraOptionsSchema.parse(extraOptions ?? {});
      const estimateTrackingToken = localStorage.getItem('est_tracking_id');
      const isReviewingEstimate = matchPath(
        {
          path: `estimates/review/:uuid`,
        },
        document.location.pathname,
      );

      if (estimateTrackingToken && isReviewingEstimate) {
        headers.set('X-Estimate-ID', estimateTrackingToken);
      }

      if (token && !EXCLUDED_ENDPOINTS.includes(api.endpoint) && requireAuth) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });

  if (typeof arg === 'string') {
    return commonFetchBaseQuery({ url: arg }, api, {});
  }

  return commonFetchBaseQuery(arg, api, {});
};

const baseQueryWithReauth: BaseQueryFn<
  string | CustomFetchArgs,
  unknown,
  FetchBaseQueryError,
  BaseQueryCustomOptions
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions ?? {});
  const state = api.getState() as RootState;
  const authenticateHeader =
    result.meta?.response?.headers.get('WWW-Authenticate');
  const isTokenExpired =
    state.auth.isAuthenticated && Date.now() > state.auth.expiresIn;
  const originalStatus =
    result.error?.status === 'PARSING_ERROR'
      ? result.error.originalStatus
      : result.error?.status;
  const statusCode =
    typeof result.error?.status === 'number'
      ? result.error?.status
      : originalStatus;

  if (
    result.error &&
    statusCode === 401 &&
    (authenticateHeader === 'error="invalid_token"' || isTokenExpired)
  ) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      const refreshToken = (api.getState() as RootState).auth.refreshToken;
      try {
        // try to get a new token
        const refreshResult = await baseQuery(
          {
            url: 'auth/login',
            method: 'POST',
            body: { refreshToken: refreshToken },
          },
          api,
          { requireAuth: false },
        );
        if (refreshResult.data) {
          const data = refreshResult.data as any;
          api.dispatch(
            updateTokens({
              accessToken: data.accessToken as string,
              refreshToken: data.refreshToken as string,
            }),
          );
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logOut());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const baseAPI = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  tagTypes: [
    'Customers',
    'Employees',
    'Skills',
    'Work-Type',
    'Service-Location',
    'Work-Requests',
    'Estimates',
    'Jobs',
    'Invoices',
    'Clients',
    'Schedules',
    'Resellers',
    'Datastore',
    'Reports',
    'Equipments',
  ],
});

export const getErrorMessage = (
  error: FetchBaseQueryError | SerializedError,
  defaultMessage = 'Unknown Error',
): string => {
  if ('status' in error && isAPIError(error.data)) {
    // Custom message for 403 handling
    if (error.status === 403 && error.data.message === 'User is disabled') {
      return 'Account is deactivated';
    }
    return error.data.message; // Default message for other API errors
  }
  return defaultMessage; // For non-API errors
};

export const isMutationSuccess = <T = unknown>(
  result:
    | {
        data: T;
      }
    | {
        error: FetchBaseQueryError | SerializedError;
      },
): result is { data: T } => {
  if ('data' in result) return true;
  return false;
};

export const isMutationFailed = <T = unknown>(
  result:
    | {
        error: FetchBaseQueryError | SerializedError;
      }
    | {
        data: T;
      },
): result is { error: FetchBaseQueryError | SerializedError } => {
  if ('error' in result) return true;
  return false;
};

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
  error: unknown,
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is {
  status: number;
  data: {
    error: string;
    message: string;
    status: number;
  };
} {
  return (
    isFetchBaseQueryError(error) &&
    'data' in error &&
    typeof (error.data as any).message === 'string'
  );
}

const paginationHeaderSchema = z.object({
  page: z.coerce.number(),
  'page-size': z.coerce.number(),
  'total-elements': z.coerce.number(),
  'total-pages': z.coerce.number(),
});
export function toPaginatedList(res: any, headers?: Headers) {
  const paginationMeta: Omit<PaginatedResponse<unknown>, 'list'> = {
    pageNumber: 0,
    pageSize: 0,
    totalElements: 0,
    totalPages: 0,
  };

  if (headers) {
    const validated = paginationHeaderSchema.safeParse(
      Object.fromEntries(headers.entries()),
    );
    if (validated.success) {
      const data = validated.data;
      paginationMeta.pageNumber = data.page;
      paginationMeta.pageSize = data['page-size'];
      paginationMeta.totalElements = data['total-elements'];
      paginationMeta.totalPages = data['total-pages'];
    }
  } else {
    paginationMeta.pageNumber = res.pageNumber;
    paginationMeta.pageSize = res.pageSize;
    paginationMeta.totalElements = res.totalElements;
    paginationMeta.totalPages = res.totalPages;
  }

  /**
   * TODO:
   * the `list` value should be refactored when
   * the migration of pagination headers are completed.
   */
  return {
    list: headers ? res : res.data,
    pageNumber: paginationMeta.pageNumber,
    pageSize: paginationMeta.pageSize,
    totalElements: paginationMeta.totalElements,
    totalPages: paginationMeta.totalPages,
  } as PaginatedResponse<any>;
}

export const withPaginationInfo = <T>(
  data: T,
  headers?: Headers,
): WithPaginationResponse<T> => {
  const paginationMeta: Omit<PaginatedResponse<unknown>, 'list'> = {
    pageNumber: 0,
    pageSize: 0,
    totalElements: 0,
    totalPages: 0,
  };

  if (headers) {
    const validated = paginationHeaderSchema.safeParse(
      Object.fromEntries(headers.entries()),
    );
    if (validated.success) {
      const data = validated.data;
      paginationMeta.pageNumber = data.page;
      paginationMeta.pageSize = data['page-size'];
      paginationMeta.totalElements = data['total-elements'];
      paginationMeta.totalPages = data['total-pages'];
    }
  }

  return {
    ...data,
    pageNumber: paginationMeta.pageNumber,
    pageSize: paginationMeta.pageSize,
    totalElements: paginationMeta.totalElements,
    totalPages: paginationMeta.totalPages,
  };
};

export const toPaginatedSearchParams = (
  args: PaginatedParams,
  options?: { defaultPage: number; defaultLimit: number },
) => {
  const defaultValues: Required<PaginatedParams> = {
    page: options?.defaultPage ?? 1,
    limit: options?.defaultLimit ?? 10,
  };

  return Object.entries(args).reduce((all, entry) => {
    const [key, value] = entry;
    const paramValue = value ?? defaultValues[key as keyof PaginatedParams];
    return { ...all, [`${entry[0]}`]: `${paramValue}` };
  }, {} as Record<string, string>);
};
